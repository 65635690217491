import { Outlet, useNavigate } from "react-router-dom";
import Header from "./ui/Header";
import { useEffect, useState } from "react";
import Container from "./ui/Container";
import Sidebar from "./ui/Sidebar";
import Panel from "./ui/panel/Panel";
import Pages from "../utils/Pages";

// const GOD = [
//   {
//     parent: "Operasyonel İşlemler",
//     icon: <FontAwesomeIcon icon={faRocket} />,
//     children: [
//       {
//         text: "Başarılı Yatırım İşlemleri",
//         to: "/success-deposit",
//       },
//       {
//         text: "Reddedilen Yatırım İşlemleri",
//         to: "/rejection-deposit",
//       },
//       {
//         text: "Bekleyen Yatırım İşlemleri",
//         to: "/waiting-deposit",
//       },
//       {
//         text: "Başarılı Çekim İşlemleri",
//         to: "/success-withdraw",
//       },
//       {
//         text: "Reddedilen Çekim İşlemleri",
//         to: "/rejection-withdraw",
//       },
//       {
//         text: "Bekleyen Çekim İşlemleri",
//         to: "/waiting-withdraw",
//       },
//     ],
//   },
//   {
//     parent: "Tanımlamalar",
//     icon: <FontAwesomeIcon icon={faSliders} />,
//     children: [
//       {
//         text: "Oyuncular",
//         to: "/players",
//       },
//       {
//         text: "Kullanıcılar",
//         to: "/users",
//       },
//       {
//         text: "Kullanıcı Oluştur",
//         to: "/create-user",
//       },
//       {
//         text:
//           process.env.REACT_APP_PAPARA_ENABLED === "true"
//             ? "Papara Hesapları"
//             : "Banka Hesapları",
//         to: "/bank-accounts",
//       },
//     ],
//   },
//   {
//     parent: "Finansal İşlemler",
//     icon: <FontAwesomeIcon icon={faCoins} />,
//     children: [
//       {
//         text: "Mutabakat Ekranı",
//         to: "/consensus",
//       },
//       {
//         text: "Havuz Ayarları",
//         to: "/pools",
//       },
//       {
//         text: "Loglar",
//         to: "/logs",
//       },
//       {
//         text: "Web Siteleri",
//         to: "/websites",
//       },

//       {
//         text: "Kasa ve Teslimat",
//         to: "/cash-delivery",
//       },
//     ],
//   },
// ];

// const SUPER_ADMIN = [
//   {
//     parent: "Operasyonel İşlemler",
//     icon: <FontAwesomeIcon icon={faRocket} />,
//     children: [
//       {
//         text: "Başarılı Yatırım İşlemleri",
//         to: "/success-deposit",
//       },
//       {
//         text: "Reddedilen Yatırım İşlemleri",
//         to: "/rejection-deposit",
//       },
//       {
//         text: "Bekleyen Yatırım İşlemleri",
//         to: "/waiting-deposit",
//       },
//       {
//         text: "Başarılı Çekim İşlemleri",
//         to: "/success-withdraw",
//       },
//       {
//         text: "Reddedilen Çekim İşlemleri",
//         to: "/rejection-withdraw",
//       },
//       {
//         text: "Bekleyen Çekim İşlemleri",
//         to: "/waiting-withdraw",
//       },
//     ],
//   },
//   {
//     parent: "Tanımlamalar",
//     icon: <FontAwesomeIcon icon={faSliders} />,
//     children: [
//       {
//         text: "Oyuncular",
//         to: "/players",
//       },
//       {
//         text: "Kullanıcılar",
//         to: "/users",
//       },
//       {
//         text: "Kullanıcı Oluştur",
//         to: "/create-user",
//       },
//       {
//         text:
//           process.env.REACT_APP_PAPARA_ENABLED === "true"
//             ? "Papara Hesapları"
//             : "Banka Hesapları",
//         to: "/bank-accounts",
//       },
//     ],
//   },
//   {
//     parent: "Finansal İşlemler",
//     icon: <FontAwesomeIcon icon={faCoins} />,
//     children: [
//       {
//         text: "Mutabakat Ekranı",
//         to: "/consensus",
//       },
//       {
//         text: "Loglar",
//         to: "/logs",
//       },
//       {
//         text: "Havuz Ayarları",
//         to: "/pools",
//       },

//       {
//         text: "Kasa ve Teslimat",
//         to: "/cash-delivery",
//       },
//     ],
//   },
// ];

// const ADMIN = [
//   {
//     parent: "Operasyonel İşlemler",
//     icon: <FontAwesomeIcon icon={faRocket} />,
//     children: [
//       {
//         text: "Başarılı Yatırım İşlemleri",
//         to: "/success-deposit",
//       },
//       {
//         text: "Reddedilen Yatırım İşlemleri",
//         to: "/rejection-deposit",
//       },
//       {
//         text: "Bekleyen Yatırım İşlemleri",
//         to: "/waiting-deposit",
//       },
//       {
//         text: "Başarılı Çekim İşlemleri",
//         to: "/success-withdraw",
//       },
//       {
//         text: "Reddedilen Çekim İşlemleri",
//         to: "/rejection-withdraw",
//       },
//       {
//         text: "Bekleyen Çekim İşlemleri",
//         to: "/waiting-withdraw",
//       },
//     ],
//   },
//   {
//     parent: "Finansal İşlemler",
//     icon: <FontAwesomeIcon icon={faCoins} />,
//     children: [
//       {
//         text: "Mutabakat Ekranı",
//         to: "/consensus",
//       },
//       {
//         text: "Loglar",
//         to: "/logs",
//       },
//     ],
//   },
//   {
//     parent: "Tanımlamalar",
//     icon: <FontAwesomeIcon icon={faSliders} />,
//     children: [
//       {
//         text:
//           process.env.REACT_APP_PAPARA_ENABLED === "true"
//             ? "Papara Hesapları"
//             : "Banka Hesapları",
//         to: "/bank-accounts",
//       },
//     ],
//   },
// ];

// const USER = [
//   {
//     parent: "Operasyonel İşlemler",
//     icon: <FontAwesomeIcon icon={faRocket} />,
//     children: [
//       {
//         text: "Başarılı Yatırım İşlemleri",
//         to: "/success-deposit",
//       },
//       {
//         text: "Reddedilen Yatırım İşlemleri",
//         to: "/rejection-deposit",
//       },
//       {
//         text: "Bekleyen Yatırım İşlemleri",
//         to: "/waiting-deposit",
//       },
//       {
//         text: "Başarılı Çekim İşlemleri",
//         to: "/success-withdraw",
//       },
//       {
//         text: "Reddedilen Çekim İşlemleri",
//         to: "/rejection-withdraw",
//       },
//       {
//         text: "Bekleyen Çekim İşlemleri",
//         to: "/waiting-withdraw",
//       },
//     ],
//   },
//   {
//     parent: "Finansal İşlemler",
//     icon: <FontAwesomeIcon icon={faCoins} />,
//     children: [
//       {
//         text: "Mutabakat Ekranı",
//         to: "/consensus",
//       },
//       {
//         text: "Loglar",
//         to: "/logs",
//       },
//     ],
//   },
// ];

// const WEBSITE = [
//   {
//     parent: "Operasyonel İşlemler",
//     icon: <FontAwesomeIcon icon={faRocket} />,
//     children: [
//       {
//         text: "Başarılı Yatırım İşlemleri",
//         to: "/success-deposit",
//       },
//       {
//         text: "Reddedilen Yatırım İşlemleri",
//         to: "/rejection-deposit",
//       },
//       {
//         text: "Bekleyen Yatırım İşlemleri",
//         to: "/waiting-deposit",
//       },
//       {
//         text: "Başarılı Çekim İşlemleri",
//         to: "/success-withdraw",
//       },
//       {
//         text: "Reddedilen Çekim İşlemleri",
//         to: "/rejection-withdraw",
//       },
//       {
//         text: "Bekleyen Çekim İşlemleri",
//         to: "/waiting-withdraw",
//       },
//     ],
//   },
//   {
//     parent: "Finansal İşlemler",
//     icon: <FontAwesomeIcon icon={faCoins} />,
//     children: [
//       {
//         text: "Mutabakat Ekranı",
//         to: "/consensus",
//       },
//       {
//         text: "Loglar",
//         to: "/logs",
//       },
//     ],
//   },
// ];

const RootLayout = () => {
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(false);

  const handleSidebar = () => setSidebar(!sidebar);

  const profile =
    JSON.parse(localStorage.getItem("remoteProfile")) ||
    JSON.parse(localStorage.getItem("profile"));

  const filterPagesByPermissions = (pages, permissions) => {
    return pages
      .map((category) => {
        const filteredChildren = category.children.filter((child) => {
          return permissions[child.permissionName];
        });

        return {
          ...category,
          children: filteredChildren,
        };
      })
      .filter((category) => category.children.length > 0);
  };

  const filteredOperationalPages = filterPagesByPermissions(
    Pages.operationalPages,
    profile.viewPermissions.operationalPages
  );

  const filteredDefinitionPages = filterPagesByPermissions(
    Pages.definitionPages,
    profile.viewPermissions.definitionPages
  );

  const filteredFinancialPages = filterPagesByPermissions(
    Pages.financialPages,
    profile.viewPermissions.financialPages
  );

  const filteredPages = {
    operationalPages: filteredOperationalPages,
    definitionPages: filteredDefinitionPages,
    financialPages: filteredFinancialPages,
  };

  // Pages.operationalPages = filteredPages.operationalPages;
  // Pages.definitionPages = filteredPages.definitionPages;
  // Pages.financialPages = filteredPages.financialPages;

  const links = [
    ...filteredPages.operationalPages,
    ...filteredPages.definitionPages,
    ...filteredPages.financialPages,
  ];

  useEffect(
    function () {
      if (!profile.viewPermissions || !profile.functionalPermissions) {
        localStorage.clear();
        navigate("/login");
      }
    },
    [profile, navigate]
  );

  return (
    <div className="App">
      <aside className="hidden lg:block ">
        <Panel links={links} />
      </aside>
      <Sidebar show={sidebar} handleSidebar={handleSidebar} links={links} />
      <main>
        <Header handleSidebar={handleSidebar} className={"mb-12"} />
        <Container>
          {/* A placeholder for pages */}
          <Outlet />
        </Container>
      </main>
    </div>
  );
};

export default RootLayout;
